import React from "react";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../components/layouts/secondary-landing";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import SEO from "../components/seo/seo";

const Accessibility = () => {
  const title = "Our Commitment to Accessibility";
  const description =
    "At WaFd Bank, we are dedicated to meeting accessibility standards and ensuring that our website's content is easily accessible and user-friendly for everyone.";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/accessibility"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-protect-accessibility-02212023-250.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      active: "true",
      title: "Accessibility"
    }
  ];

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section id="accessibility-section" className="container">
        <h1>Our Commitment to Accessibility</h1>
        <div className="row">
          <div className="col-sm-6">
            <h4 className="text-success mb-4">
              At WaFd Bank, we are dedicated to meeting accessibility standards and ensuring that our website's content
              is easily accessible and user-friendly for everyone.
            </h4>
            <p>
              Should you encounter any difficulty viewing or navigating our website, or if you notice any content,
              feature, or functionality that may not be fully accessible, please do not hesitate to contact our Customer
              Service team at{" "}
              <a id="accessibility-c3-telephone" href="tel:800-324-9375">
                800-324-9375
              </a>{" "}
              or send us an email at{" "}
              <a id="accessibility-wafd-email" href="mailto:accessibility@wafd.com">
                accessibility@wafd.com
              </a>
              . In the email subject line, kindly include &quot;Website Accessibility&quot; and provide a description of
              the particular feature that you feel is not fully accessible, or suggest an improvement. We value your
              feedback and take it seriously, as we strive to improve the accessibility of our website.
            </p>
          </div>
          <div className="d-none d-sm-block col-6">
            <StaticImage
              src="../images/thumbnail-accessibility-02212023-730.jpg"
              alt="Man sitting on a couch using a laptop"
              placeholder="blurred"
              quality="100"
            />
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default Accessibility;
